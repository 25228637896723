
@import '/css/ionicons.min.css';

/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}
.ant-form-item-children-icon {
  display: none;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.stamp {
  transform: rotate(12deg);
  color: #555;
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  /*-webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px;*/
  mix-blend-mode: multiply;
  z-index: 2;
  position: absolute;
}

.is-inactive {
  color: #d23;
  border: 0.5rem double #d23;
  transform: rotate(-18deg);
  /* -webkit-mask-position: 2rem 3rem; */
  font-size: 4em;
}

.is-approved {
  color: #0a9928;
  border: 0.5rem solid #0a9928;
  /* -webkit-mask-position: 13rem 6rem; */
  transform: rotate(-14deg);
  border-radius: 0;
}

.is-draft {
  color: #c4c4c4;
  border: 0.5rem double #c4c4c4;
  transform: rotate(-18deg);
  font-size: 4rem;
  /* font-family: "Open sans", Helvetica, Arial, sans-serif;
    border-radius: 0;*/
  padding: 0.5rem;
}

.is-dev {
  color: #c4c4c4;
  border: 0.5rem double #c4c4c4;
  transform: rotate(-10deg);
  font-size: 2rem;
  /* font-family: "Open sans", Helvetica, Arial, sans-serif;
    border-radius: 0;*/
  padding: 0.5rem;
}

.is-demo {
  color: #c4c4c4;
  border: 0.5rem double #c4c4c4;
  transform: rotate(-10deg);
  font-size: 2rem;
  /* font-family: "Open sans", Helvetica, Arial, sans-serif;
    border-radius: 0;*/
  padding: 0.5rem;
}


.partSideBar  .ant-collapse  .ant-collapse-item  .ant-collapse-header .ant-collapse-arrow {
  top: 3px
}

.partSideBar  .ant-collapse  .ant-collapse-item  .ant-collapse-header {
  padding: 0px 16px;
  padding-left: 0px;
}

.partSideBar .ant-collapse-content {
  border-top: unset;
}

.ais-SearchBox__wrapper {
margin-top: 5px;
}

.partSideBar .ant-collapse > .ant-collapse-item {
  border-bottom: unset;
  padding-bottom: unset;
  margin-bottom: 15px;
}

.partSideBar .ant-collapse > .ant-collapse-item-active {
  padding-bottom: 15px;
}

.partSideBar .ant-collapse-content > .ant-collapse-content-box {
  padding: unset;
}

.partSideBar .isoAlgoliaSidebarItem {
   padding-bottom: unset !important;
}

.isoAlgoliaSidebarTitle {
  display: inline !important;
  margin-bottom: 15px;
}

.custom-tag-unit {
  .ant-tag-has-color {
    color: blue !important;
    font-weight: 400;
    color: #FFF;
  }
}

.custom-tag-domain {
  color: black;
}

// .ant-list-item:hover {
//   color: #f5f5f5;
// }